export interface LoginFormSchema {
    email: string;
    password: string
}

export interface SignUpFormSchema {
    email: string;
    password: string;
    repassword?: string;
    username: string;
    referralId?: string;
}

export interface NewPasswordSchema {
    password?: string;
    repassword?: string;
}

export interface ResetPasswordBody {
    password?: string;
    token?: string;
}

export interface ForgotPasswordFormSchema {
    email: string;
}

export enum StatusEnum {
    ACTIVE = 'active',
    BANNED = 'banned',
    SUSPENCE = 'suspence'
}

export interface ChangePasswordSchema {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface ChangePasswordBody {
    password?: string;
    newPassword?: string;
}

export interface ExchangeSchema {
    amount: number
}

export interface VerifyShortLinkSchema {
    key: string;
}